@tailwind base;
@tailwind components;
@tailwind utilities;

/* Layout */
header{
  margin-bottom: 10rem;
}

.title {
  font-family: 'MedievalSharp';
  font-weight: 400;
  color: #ffda00;
  position: fixed;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

main {
  background-color: #f1efea;
  margin: 0 auto;
  border-radius: 10px 10px 0 0;
  padding: 0;
  width: 60vw;
  font-family: 'Roboto';
  min-height: 75vh;
  border-top: 6px groove #a85c00;
  border-left: 6px groove #a85c00;
  border-right: 6px groove #a85c00;
}

footer{
  background: #0c0938fa;
  border-radius: 0 0 10px 10px;
  border-left: 6px groove #a85c00;
  border-right: 6px groove #a85c00;
  border-bottom: 6px groove #a85c00;
}

body{
 background-image: url("../public/background.jpg");
 background-size: cover;
 background-repeat: no-repeat;
 background-attachment: fixed;

}

/* Navbar */

.navbar {
  display: flex;
  list-style-type: none;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 0 0 1rem 1rem;
  width: 25vw;
  margin: 0 auto 0 auto;
  transform: translateY(120%);
}

.navDecoration{
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  z-index: -1;
}

.navlink {
  font-family: 'MedievalSharp';
  font-weight: 300;
  text-decoration: none;
  margin-top: 2rem;
}

.resistImage{
  width: 1.8rem;
  transform: translate(-40%, -35%);
}

.navlink.active::after, .navlink:hover::after{
  width: 100%;
  margin:auto;
}
.navlink.active::after, .navlink:hover::after{
  width: 100%;
  margin:auto;
}

.navlink.active{
  color: #ffda00;
}

.navlink::after{
  content:'';
  width: 0%;
  height: 2px;
  background-color: #ffda00;
  position: relative;
  bottom: -0.1rem;
  transition: 300ms;
  display: block;
}
/* Boss Tables */
.tableHeader{
  position: absolute;
  left: 50%;
  transform: translate(-50%, -80%);
  min-width: 300px;
  z-index: 1;
}

.tableImg{
  position: absolute;
  transform: translateY(-120%);
}

/* Components */

h2, h1{
  font-family: 'MedievalSharp';
}

.selector.active{
  background-color: #3a3a3a !important;
  opacity: 1;
  transition-duration: 300ms;
  color: rgb(218, 216, 216);
}

.selector{
  background-color: white;
  border: black 1px solid;
  padding: 2px;
  border-radius: 6px;
  width: 150px;
  margin-right: 10px;
}

.selector:hover{
  background-color: #3a3a3a;
  opacity: 1;
  transition-duration: 300ms;
  color: rgb(218, 216, 216);
}

#backToTopButton {
  right: 10%;
}

/* Aside */

.carousel{
  overflow: hidden;
  height: 50vh;
  position: relative;
}

.slides{
  display: flex;
  flex-direction: column;
  animation: slide 500s infinite linear;
}

.slides:hover{
  animation-play-state: paused;
}

.slide{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide > span{
  font-family: 'MedievalSharp';
  font-weight: 600;
}

@keyframes slide{
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media (max-width: 1000px) { 
  aside{
    display: none;
  }

  .page {
    margin: 0 auto;
    padding: 0;
    width: 100vw;
    font-family: 'Roboto';
    min-height: 75vh;
    overflow-x: hidden;
  }

  body{
    display: flex;
  }

  .title{
    font-size: 0.5rem;
  }

  .navbar {
    display: flex;
    list-style-type: none;
    margin: 1rem auto 0;
    padding: 1.5rem;
    width: 100vw;
    border-radius: 0.5rem 0.5rem 0 0;
  }
    
  footer{
    width: 100vw;
  }
}

@media (max-width: 1700px) { 
  .title{
    font-size: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
